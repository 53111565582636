import { Quill } from 'vue-quill-editor'
const BlockEmbed = Quill.import('blots/block/embed')
const Link = Quill.import('formats/link')
import { env } from "@/utils";
class Video extends BlockEmbed {
  static create (value) {
    const node = super.create(value)
    node.dataset.fileInfo = value;
    node.setAttribute('contenteditable', false);
    const fileInfo = JSON.parse(value);
    node.setAttribute('style', 'width:500px;height:85px;border-radius:8px;background:#F7F7F7;box-sizing:border-box;cursor:pointer;margin-bottom:15px;user-select:none');
    // 文件下载地址
    let downloadFileUrl = `${process.env.VUE_APP_BASEURL}/rest/user/download?downloadUrl=${formartImageUrl(fileInfo.path)}&fileName=${fileInfo.name.replace(/<\/?.+?\/?>/g,"")}`;
    let filePreview =
    `<div style='width: 100%; height:100%;position:relative;display:flex;align-items:center;justify-content:flex-start;padding-left:25px;' onclick='window.open("${downloadFileUrl}","_blank")'>\
      <div style='width:52px;height:56px;flex-shrink:0;overflow:hidden;display:flex;align-items:center;justify-content:center;'>\
        <img style='width:100%' src='${getFileIcon(fileInfo.name)}'>
      </div>\
      <div style='height:56px;flex:1;padding:0 25px;overflow:hidden;text-align:left;line-height:56px;color:#373737;font-size:14px;font-weight:bold;text-overflow:ellipsis;white-space:nowrap;'>${fileInfo.name}</div>\
    </div>`;
    node.innerHTML = filePreview;
    return node
  }
  static value(domNode) {
    // 输出到Delta内部内容
    return domNode.dataset.fileInfo;
  }
}
Video.blotName = 'file' // 需要调用的时候的标签
Video.tagName = 'div' //html标签

// 获取图片对应的ICON
function getFileIcon(fileName) {
  if(judgment(fileName,'txt') ){
    return 'https://imfile.melinked.com/2021/07/09dadd1e-8843-4842-b64e-d51a960cdfae.png';
  } else if(judgment(fileName,'doc') || judgment(fileName,'docx')){
    return 'https://imfile.melinked.com/2021/07/8c26e2bb-59f4-47da-9643-f00b1953d983.png';
  } else if(judgment(fileName,'pdf')){
    return 'https://imfile.melinked.com/2021/07/c15d96db-a360-4584-aee1-4a0f0acf47e2.png';
  } else if(judgment(fileName,'xlsx') || judgment(fileName,'xls')){
    return 'https://imfile.melinked.com/2021/07/16dd6626-86ea-4a08-9cda-53d41efa3194.png';
  } else if(judgment(fileName,'pptx') || judgment(fileName,'ppt')){
    return 'https://imfile.melinked.com/2021/07/44525d7d-49a9-418b-8ab3-165cff7829c8.png';
  } else if(
    judgment(fileName,'tar') || 
    judgment(fileName,'7z') ||
    judgment(fileName,'bz2') ||
    judgment(fileName,'gz') ||
    judgment(fileName,'wim') ||
    judgment(fileName,'xz') ||
    judgment(fileName,'zip')
  ){
    return 'https://imfile.melinked.com/2021/07/a459701c-b356-4b76-8d08-edefcb9c5e35.png';
  } else {
    return 'https://imfile.melinked.com/2021/07/f5b62842-9f61-4189-9a36-1e344d5a38da.png'
  }
}

// 判断是否是这个文件
function judgment(text,type) {
  let typeLength =text.length - text.lastIndexOf(type);
  return text.lastIndexOf(type) != -1 && typeLength == type.length
}
// 处理图片的下载地址
function formartImageUrl(url) {
  if (!url) {
    return "";
  } else if ( url == "video" ) {
    return url;
  } else if (
    (url.indexOf("tfile.melinked.com") !== -1 || url.indexOf("image.melinked.com") !== -1) &&
    url.indexOf("?imageView2") === -1 && 
    (url.indexOf("png") !== -1 || url.indexOf("jpg") !== -1 || url.indexOf("gif") !== -1 )
  ) {
    return url + "?imageView2";
  } else if (url.indexOf("http") === 0 || url.indexOf("blob:http") === 0) {
    return url;
  } else if (url.indexOf("/upload") === 0 || url.indexOf("me20") === 0) {
    return process.env.NODE_ENV != "production"
      ? "https://mecdntest.melinked.com/" + url
      : "https://pic.melinked.com/" + url;
  } else if (url.indexOf("/images") === 0) {
    return process.env.VUE_APP_BASEURL + url;
  }
}

export default Video;