var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "editGroupInformationBox" }, [
    _c("div", { staticClass: "groupHeaderContainer" }, [
      _c(
        "div",
        { staticClass: "groupHeaderBox" },
        [
          _c(
            "el-image",
            {
              staticClass: "avatarForGroup",
              attrs: { src: _vm.formartImageUrl(_vm.groupPhoto), fit: "cover" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "imageLoadError",
                  style: {
                    background: _vm.showPhotoTips ? "rgba(255,237,231,1)" : ""
                  },
                  attrs: { slot: "error" },
                  slot: "error"
                },
                [_c("span", { staticClass: "iconfont_Me iconStyle icon-shot" })]
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass: "maskBox",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.uploadGroupCover.apply(null, arguments)
                }
              }
            },
            [_c("span", { staticClass: "iconfont_Me icon-shot1 iconStyle" })]
          ),
          _c("el-upload", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: false,
                expression: "false"
              }
            ],
            ref: "uploadPicture",
            attrs: {
              action: "/",
              accept: "image/*",
              multiple: true,
              "http-request": _vm.handlerUpload,
              "before-upload": _vm.beforePictrueUpload
            }
          })
        ],
        1
      )
    ]),
    _c("div", { staticClass: "commonModuleStyle" }, [
      _c("div", { staticClass: "moduleTitleBlock" }, [
        _c("span", { staticClass: "redTips" }, [_vm._v("*")]),
        _c("span", { staticClass: "titleStyle" }, [
          _vm._v(_vm._s(_vm.$t("groupName")))
        ])
      ]),
      _c("div", { staticClass: "moduleContent" }, [
        _c(
          "div",
          { staticClass: "moduleItem" },
          [
            _vm.inputGroupNameFlag
              ? _c("el-input", {
                  ref: "groupnameInputter",
                  attrs: { placeholder: _vm.placeholderIcon },
                  on: { blur: _vm.groupNameBlur },
                  model: {
                    value: _vm.groupname,
                    callback: function($$v) {
                      _vm.groupname = $$v
                    },
                    expression: "groupname"
                  }
                })
              : _c(
                  "div",
                  {
                    staticClass: "showGroupNameItem",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.startInputGroupName.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _vm.groupname
                      ? _c("span", { staticClass: "groupNameText" }, [
                          _vm._v(_vm._s(_vm.groupname))
                        ])
                      : _vm._e(),
                    _c("img", {
                      staticClass: "inputIcon",
                      attrs: {
                        src: require("../../../assets/images/newEditCenter/editIcon.png"),
                        alt: ""
                      }
                    })
                  ]
                )
          ],
          1
        )
      ])
    ]),
    _c("div", { staticClass: "commonModuleStyle" }, [
      _c(
        "div",
        { staticClass: "moduleTitleBlock", style: { lineHeight: "24px" } },
        [
          _c("span", { staticClass: "redTips" }, [_vm._v("*")]),
          _c("span", { staticClass: "titleStyle" }, [
            _vm._v(_vm._s(_vm.$t("groupDescription")))
          ])
        ]
      ),
      _c("div", { staticClass: "moduleContent" }, [
        _c(
          "div",
          { staticClass: "moduleItem" },
          [
            _vm.inputgroupdescriptionFlag
              ? _c("el-input", {
                  ref: "groupdescriptionInputter",
                  attrs: {
                    type: "textarea",
                    resize: "none",
                    autosize: { minRows: 1, maxRows: 5 },
                    placeholder: _vm.placeholderIcon
                  },
                  on: { blur: _vm.groupdescriptionBlur },
                  model: {
                    value: _vm.groupdescription,
                    callback: function($$v) {
                      _vm.groupdescription = $$v
                    },
                    expression: "groupdescription"
                  }
                })
              : _c(
                  "div",
                  {
                    staticClass: "descriptionContent",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.startInputGroupdescription.apply(
                          null,
                          arguments
                        )
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.groupdescription) +
                        "\n          "
                    ),
                    _c("img", {
                      staticClass: "inputIcon",
                      attrs: {
                        src: require("../../../assets/images/newEditCenter/editIcon.png"),
                        alt: ""
                      }
                    })
                  ]
                )
          ],
          1
        )
      ])
    ]),
    _c("div", { staticClass: "commonModuleStyle" }, [
      _c(
        "div",
        { staticClass: "labelContainerItem" },
        _vm._l(_vm.tags, function(item, index) {
          return _c("Label", {
            key: index + item,
            style: { minWidth: "90px", marginBottom: "16px" },
            attrs: {
              labelContent: item,
              labelIndex: index,
              CanBeDeleted: item != "" ? true : false,
              CanBeAdded: item == "" ? true : false
            },
            on: {
              addNewLabelTag: _vm.addNewLabelTag,
              deleteLabelTag: _vm.deleteLabelTag
            }
          })
        }),
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "commonModuleStyle", staticStyle: { marginTop: "21px" } },
      [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "moduleContent" },
          [
            _c("TypeOfEditCenter", {
              style: { padding: "0 0 0 65px" },
              attrs: { categories: _vm.categories },
              on: { getHasSelectedType: _vm.getHasSelectedType }
            })
          ],
          1
        )
      ]
    ),
    _c(
      "div",
      { staticClass: "selectGroupTypeBox" },
      _vm._l(_vm.groupTypes, function(item, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "groupTypeItem",
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.selectThisType(index)
              }
            }
          },
          [
            _c("div", { staticClass: "leftBox" }, [
              _c("div", { staticClass: "groupTypeIcon" }, [
                _c("img", {
                  style: { width: "100%", height: "100%" },
                  attrs: { src: item.typeIcon }
                })
              ]),
              _c("div", { staticClass: "typeTextArea" }, [
                _c("span", { staticClass: "typeName" }, [
                  _vm._v(_vm._s(item.typeName) + ":")
                ]),
                _c("span", { staticClass: "typeDescription" }, [
                  _vm._v(_vm._s(item.typeDescription))
                ])
              ])
            ]),
            _c("div", { staticClass: "rightBox" }, [
              item.isSelected
                ? _c("img", {
                    style: { width: "100%", height: "100%" },
                    attrs: {
                      src: require("../../../assets/images/newLogin/hasSelected.png")
                    }
                  })
                : _c("img", {
                    style: { width: "100%", height: "100%" },
                    attrs: {
                      src: require("../../../assets/images/newLogin/unSelected.png")
                    }
                  })
            ])
          ]
        )
      }),
      0
    ),
    _c("div", { staticClass: "editFooterButton" }, [
      _c(
        "div",
        {
          staticClass: "buttonStyle closeButton",
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.goBackPreviousPage.apply(null, arguments)
            }
          }
        },
        [_c("span", { staticClass: "iconfont_Me icon-x iconStyle" })]
      ),
      _c(
        "div",
        {
          staticClass: "buttonStyle",
          class: _vm.canSubmitFlag ? "commomButtonHover" : "unSubmintStyle",
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.startSubmit.apply(null, arguments)
            }
          }
        },
        [_c("span", { staticClass: "iconfont_Me icon-check iconStyle" })]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "editIcon" }, [
      _c("span", { staticClass: "iconfont_Me icon-class iconStyle" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }