<template>
  <div class="editGroupInformationBox">
    <div class="groupHeaderContainer">
      <div class="groupHeaderBox">
        <el-image
          :src="formartImageUrl(groupPhoto)"
          class="avatarForGroup"
          :fit="'cover'">
          <div 
            slot="error" 
            class="imageLoadError"
            :style="{
              background:showPhotoTips?'rgba(255,237,231,1)':''
            }"
          >
            <span class="iconfont_Me iconStyle icon-shot"></span>
          </div>
        </el-image>
        <div class="maskBox" @click.stop="uploadGroupCover">
          <span class="iconfont_Me icon-shot1 iconStyle"></span>
        </div>
        <el-upload
          v-show="false"
          ref="uploadPicture"
          action="/"
          accept="image/*"
          :multiple="true"
          :http-request="handlerUpload"
          :before-upload="beforePictrueUpload"
        ></el-upload>
      </div>
    </div>
    <div class="commonModuleStyle">
      <div class="moduleTitleBlock">
        <span class="redTips">*</span>
        <span class="titleStyle">{{$t("groupName")}}</span>
      </div>
      <div class="moduleContent">
        <div class="moduleItem">
          <el-input ref="groupnameInputter" @blur="groupNameBlur" v-if="inputGroupNameFlag" v-model="groupname" :placeholder="placeholderIcon"></el-input>
          <div v-else class="showGroupNameItem" @click.stop="startInputGroupName">
            <span class="groupNameText" v-if="groupname">{{groupname}}</span>
            <img class="inputIcon" :src="require('../../../assets/images/newEditCenter/editIcon.png')" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="commonModuleStyle">
      <div class="moduleTitleBlock" :style="{lineHeight:'24px'}">
        <span class="redTips">*</span>
        <span class="titleStyle">{{$t("groupDescription")}}</span>
      </div>
      <div class="moduleContent">
        <div class="moduleItem">
          <el-input
            type="textarea" 
            resize="none"
            :autosize="{ minRows: 1, maxRows: 5 }" 
            ref="groupdescriptionInputter" 
            @blur="groupdescriptionBlur" 
            v-if="inputgroupdescriptionFlag" 
            v-model="groupdescription" 
            :placeholder="placeholderIcon"
          ></el-input>
          <div v-else class="descriptionContent" @click.stop="startInputGroupdescription">
            {{groupdescription}}
            <img class="inputIcon" :src="require('../../../assets/images/newEditCenter/editIcon.png')" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="commonModuleStyle">
      <div class="labelContainerItem">
        <Label 
          :style="{minWidth:'90px',marginBottom: '16px'}"
          @addNewLabelTag="addNewLabelTag"
          @deleteLabelTag="deleteLabelTag"
          v-for="(item,index) in tags" 
          :labelContent="item" 
          :labelIndex="index" 
          :key="index+item"
          :CanBeDeleted="item!=''?true:false" 
          :CanBeAdded="item==''?true:false" 
        ></Label>
      </div>
    </div>
    <div class="commonModuleStyle" style="marginTop: 21px;">
      <div class="editIcon">
        <span class="iconfont_Me icon-class iconStyle"></span>
      </div>
      <div class="moduleContent">
        <TypeOfEditCenter :style="{padding:'0 0 0 65px'}" :categories="categories" @getHasSelectedType="getHasSelectedType"></TypeOfEditCenter>
      </div>
    </div>
    <div class="selectGroupTypeBox">
      <div class="groupTypeItem" v-for="(item,index) in groupTypes" :key="index" @click.stop="selectThisType(index)">
        <div class="leftBox">
          <div class="groupTypeIcon">
            <img :style="{width:'100%',height:'100%'}" :src="item.typeIcon">
          </div>
          <div class="typeTextArea">
            <span class="typeName">{{item.typeName}}:</span>
            <span class="typeDescription">{{item.typeDescription}}</span>
          </div>
        </div>
        <div class="rightBox">
          <img v-if="item.isSelected" :style="{width:'100%',height:'100%'}" :src="require('../../../assets/images/newLogin/hasSelected.png')">
          <img v-else :style="{width:'100%',height:'100%'}" :src="require('../../../assets/images/newLogin/unSelected.png')">
        </div>
      </div>
    </div>
    <div class="editFooterButton">
      <div class="buttonStyle closeButton" @click.stop="goBackPreviousPage">
        <span class="iconfont_Me icon-x iconStyle"></span>
      </div>
      <div class="buttonStyle" :class="canSubmitFlag?'commomButtonHover':'unSubmintStyle'" @click.stop="startSubmit">
        <span class="iconfont_Me icon-check iconStyle"></span>
      </div>
    </div>
  </div>
</template>
<script>
import Label from "../../components/label";
import TypeOfEditCenter from "./typeOfEditCenter";
import { handlerUploadFileToQiniu } from '@/api/qiniu';
import { updateGroupDetail, createGroup } from '@/api/newVersion/groupCenter';
export default {
  name: "editGroupInformation",
  components: {
    Label,
    TypeOfEditCenter
  },
  data() {
    return {
      // 群组头像
      groupPhoto: "",
      // 上传的群组头像对象，用于监听上传进度
      groupPhotoObj: null,
      // 没有上传图片的时候显示提示色
      showPhotoTips: false,
      // 群名称
      groupname: "",
      // 是否展示输入群名称的输入框
      inputGroupNameFlag: false,
      // 昵称输入框默认文本
      placeholderIcon: '\ue6a1',
      // 群描述
      groupdescription: "",
      // 是否展示输入群描述的输入框
      inputgroupdescriptionFlag: false,
      // 群标签
      tags: [''],
      // 群分类集合
      categories: '',
      // 群类型
      groupTypes: [
        {
          typeIcon: require('../../../assets/images/newEditCenter/publicGroup.png'),
          typeName: this.$t('publicGroup'),
          typeDescription: this.$t('publicGroupDescription'),
          isSelected: true
        },
        {
          typeIcon: require('../../../assets/images/newEditCenter/memberGroup.png'),
          typeName: this.$t('memberGroup'),
          typeDescription: this.$t('memberGroupDescription'),
          isSelected: false
        },
        {
          typeIcon: require('../../../assets/images/newEditCenter/privateGroup.png'),
          typeName: this.$t('privateGroup'),
          typeDescription: this.$t('privateGroupDescription'),
          isSelected: false
        }
      ],
      // 群组类型，默认为1
      accessPattern: 1
    }
  },
  watch: {
    // 监听群组信息变化
    'groupInfo.group_id': {
      handler(val,old) {
        if(val && val !== old) {
          this.groupPhoto = this.groupInfo.group_header;
          this.groupname = this.groupInfo.group_name;
          // 过滤掉描述里的HTML标签
          this.groupdescription = this.groupInfo.group_info.replace(/<\/?.+?>/g,"").replace(/ /g,"");
          // 获取标签
          if(this.groupInfo.group_tag && this.groupInfo.group_tag !== '') {
            this.tags = this.groupInfo.group_tag.split(",");
            this.tags.push('')
          }
          // 获取类型
          if(this.groupInfo.group_category && this.groupInfo.group_category !== '') {
            // 初始化，默认选中类型
            this.categories = this.groupInfo.group_category;
          }
          this.groupTypes = this.groupTypes.map(item => {
            item.isSelected = false;
            return item;
          })
          switch (this.groupInfo.access_pattern) {
            case '1':
              this.groupTypes[0].isSelected = true;
              this.accessPattern = 1;
              break;
            case '2':
              this.groupTypes[1].isSelected = true;
              this.accessPattern = 2;
              break;
            case '3':
              this.groupTypes[2].isSelected = true;
              this.accessPattern = 3;
              break;
          }
        }
      },
      deep: true
    },
    // 监听昵称输入框的状态
    inputGroupNameFlag: {
      handler(val,old) {
        if(val && val !== old) {
          this.$nextTick(() => {
            this.$refs.groupnameInputter.focus();
          })
        }
      },
      deep: true
    },
    // 监听昵称输入框的状态
    inputgroupdescriptionFlag: {
      handler(val,old) {
        if(val && val !== old) {
          this.$nextTick(() => {
            this.$refs.groupdescriptionInputter.focus();
          })
        }
      },
      deep: true
    },
    // 监听上传封面的对象
    groupPhotoObj: {
      handler(val,old) {
        if(val.progress === '100.00') {
          this.groupPhoto = val.path
          this.showPhotoTips = false;
        }
      },
      deep: true
    }
  },
  computed: {
    // 是否可以提交
    canSubmitFlag() {
      return this.groupPhoto !== '' && this.globalTrim(this.groupname) !== '' && this.globalTrim(this.groupdescription) !== '' && this.categories !== '' 
    }
  },
  props: {
    // 编辑群信息需要的群信息
    groupInfo: {
      type: Object,
      default: ()=>{
        return {}
      }
    }
  },
  methods: {
    // 上传群组封面
    uploadGroupCover() {
      this.$refs.uploadPicture.$children[0].$refs.input.click();
    },
    // 在上传图片以前执行检查
    beforePictrueUpload(file, fileList) {
      if(this.matchFileSuffixType(file.name) === 'image' && file.size > 0 && (file.size/(1024 * 1024)).toFixed(2) < 200) {
        return true;
      }
      return false;
    },
    // 开始上传图片
    handlerUpload(files) {
      handlerUploadFileToQiniu(files).then( async (item) => {
        this.$refs.uploadPicture.clearFiles();
        this.groupPhotoObj = item;
      })
    },
    // 开始输入群名称
    startInputGroupName() {
      this.inputGroupNameFlag = true;
    },
    // 群名称输入框失去焦点
    groupNameBlur() {
      this.inputGroupNameFlag = false;
    },
    // 开始输入群描述
    startInputGroupdescription() {
      this.inputgroupdescriptionFlag = true;
    },
    // 群描述输入框失去焦点
    groupdescriptionBlur() {
      this.inputgroupdescriptionFlag = false;
    },
    // 添加新的标签
    addNewLabelTag(text,index){
      if(this.tags[index] == ''){
        this.tags[index] = text;
        this.tags.push('');
      }
    },
    // 删除标签
    deleteLabelTag(index){
      this.tags.splice(index,1);
    },
    // 获取已选中的类型
    getHasSelectedType(hasSelectedType) {
      this.categories = '';
      if(hasSelectedType.length !== 0) {
        hasSelectedType.forEach(element => {
          this.categories += element.id + ','
        });
        this.categories = this.categories.slice(0,this.categories.length-1);
      }
    },
    // 选择这个群的类型
    selectThisType(index) {
      this.groupTypes = this.groupTypes.map(item => {
        item.isSelected = false;
        return item;
      })
      this.groupTypes[index].isSelected = true;
      this.accessPattern = index + 1;
      this.$forceUpdate();
    },
    // 处理群提交的信息
    handleGroupInfo() {
      // 这个主要是为了模拟老接口的数据格式
      let imageData = {data_id:"",data_type:"",duration:"",id:"",name:"",path:this.groupPhoto,size:"",type:3,progress:"100.00"}
      let handlerTags = this.tags.join(",");
      if(handlerTags !== '') {
        handlerTags = handlerTags.slice(0,handlerTags.length-1);
      }
      let langcode = window.localStorage.getItem("langCode");
      let params = {
        language: langcode ? parseInt(langcode) : 1,
        userId: this.$store.getters.userInfo.id,
        groupName: this.groupname,
        categoryId: this.categories,
        tags: handlerTags,
        accessPattern: this.accessPattern,
        groupDetail: this.groupdescription,
        imageData: JSON.stringify([imageData]),
      };
      if(this.groupInfo && this.groupInfo.group_id) {
        params.groupId = this.groupInfo.group_id;
        params.providerId = this.groupInfo.group_id;
      }
      return params;
    },
    // 绘制没有填写的提示
    drawingTips() {
      // 如果没有上传封面
      if(this.groupPhoto === '') {
        this.showPhotoTips = true;
      }
    },
    // 开始上传信息
    async startSubmit() {
      if(!this.canSubmitFlag) {
        this.drawingTips();
        return;
      }
      let params = this.handleGroupInfo();
      let result = null;
      // 如果存在群组ID，则是编辑群信息
      if(this.groupInfo && this.groupInfo.group_id) {
        result = await updateGroupDetail(this.groupInfo.group_id,params);
      } else {
        result = await createGroup(params);
      }
      if(result.code == 200 || result.code ==0) {
        this.goBackPreviousPage();
      } else {
        this.$message({
          type: "error",
          message: result.message
        })
      }
    },
    // 回到个人中心页面
    goBackPreviousPage() {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="stylus" scoped>
  .editGroupInformationBox
    width 100%;
    overflow hidden;
    .groupHeaderContainer
      height 126px;
      width 100%;
      overflow hidden;
      .groupHeaderBox
        height 100%;
        width 246px;
        overflow hidden;
        position relative;
        &:hover
          .maskBox
            opacity 1;
        .maskBox
          top 0;
          left 0;
          z-index 2;
          width 100%;
          height 100%;
          opacity 0;
          cursor pointer;
          position absolute;
          transition opacity .3s;
          background rgba(0,0,0,0.4);
          line-height 126px;
          text-align center;
          .iconStyle
            font-size 36px;
            color #FFFFFF;
    .commonModuleStyle
      width 100%;
      margin-top 40px;
      padding-bottom 20px;
      border-bottom 1px solid #E6E6E6;
      overflow hidden;
      display flex;
      align-items flex-start;
      justify-content space-between;
      .editIcon
        width 32px;
        height 32px;
        flex-shrink 0;
        margin-left 12px;
        border-radius 50%;
        background #F5F5F5;
        display flex;
        align-items center;
        justify-content center;
        .iconStyle
          font-size 20px;
          color #333333;
      .moduleTitleBlock
        min-width 60px;
        line-height 30px;
        padding-left 14px;
        flex-shrink 0;
        .redTips
          color #FB0000;
          font-size 14px;
          margin-right 3px;
        .titleStyle
          color #343434;
          font-size 14px;
      .moduleContent
        flex 1;
        min-width 0;
        overflow hidden;
        .moduleItem
          padding 0 25px 0 40px;
          box-sizing border-box;
          .showGroupNameItem
            height 30px;
            cursor pointer;
            user-select none;
            font-size 14px;
            color #343434;
            display flex;
            align-items center;
            justify-content flex-start;
            .groupNameText
              margin-right 20px;
            .inputIcon
              width 12px;
              height 12px;
        .descriptionContent
          width 100%;
          cursor pointer;
          overflow hidden;
          line-height 24px;
          color #343434;
          font-size 14px;
          padding 0;
      .labelContainerItem
        width 100%;
        overflow hidden;
        min-height 30px;
        padding-left 100px;
        box-sizing border-box;
    .selectGroupTypeBox
      width 100%;
      padding 30px 12px;
      .groupTypeItem
        width 100%;
        min-height 40px;
        cursor pointer;
        overflow hidden;
        display flex;
        align-items center;
        justify-content space-between;
        margin-bottom 10px;
        &:last-child
          margin-bottom 0;
        .leftBox
          flex 1;
          min-width 0;
          overflow hidden;
          display flex;
          align-items center;
          justify-content space-between;
          .groupTypeIcon
            width 20px;
            height 20px;
            flex-shrink 0;
            margin-right 24px;
          .typeTextArea
            flex 1;
            min-width 0;
            font-size 14px;
            color #343434;
            user-select none;
            .typeName
              font-weight bold;
              margin-right 8px;
        .rightBox
          width 18px;
          height 18px;
    .editFooterButton
      width 100%;
      height 34px;
      margin-top 50px;
      display flex;
      align-items center;
      justify-content center;
      .buttonStyle
        height 100%;
        width 90px;
        border-radius 4px;
        cursor pointer;
        user-select none;
        display flex;
        align-items center;
        justify-content center;
        transition background .3s;
        &:first-child
          margin-right 10px;
        .iconStyle
          color #FFFFFF;
          font-size 22px;
      .unSubmintStyle
        background #CCCCCC;
      .closeButton
        background #E6E6E6;
        &:hover
          background #F0F0F0


</style>
<style lang="stylus">
  .moduleContent
    .moduleItem
      .el-input
        .el-input__inner
          font-family 'iconfont_Me';
          caret-color #33CC66;
          height 30px;
          line-height 30px;
          border none !important;
          padding 0 !important;
      .el-textarea
        .el-textarea__inner
          font-family 'iconfont_Me';
          caret-color #33CC66;
          line-height 24px;
          border none !important;
          padding 0 !important;
  .avatarForGroup
    width 100%
    height 100%;
    .imageLoadError
      height 100%;
      width 100%;
      background #E4E5E9;
      display flex;
      align-items center;
      justify-content center;
      transition background .3s;
      .iconStyle
        font-size 24px;
        color #999999

</style>