var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "editPersonalInformationBox" }, [
    _c("div", { staticClass: "personalHeaderAndId" }, [
      _c(
        "div",
        { staticClass: "personalHeaderBox" },
        [
          _c(
            "el-image",
            {
              staticClass: "avatarForPersonal",
              attrs: {
                src: _vm.formartImageUrl(_vm.personnalAvatar),
                fit: "cover"
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "imageLoadError",
                  attrs: { slot: "error" },
                  slot: "error"
                },
                [
                  _c("span", {
                    staticClass: "iconfont_Me iconStyle icon-group-fill"
                  })
                ]
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass: "maskBox",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.uploadPersonalAvatar.apply(null, arguments)
                }
              }
            },
            [_c("span", { staticClass: "iconfont_Me icon-shot1 iconStyle" })]
          ),
          _c("el-upload", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: false,
                expression: "false"
              }
            ],
            ref: "uploadPicture",
            attrs: {
              action: "/",
              accept: "image/*",
              multiple: true,
              "http-request": _vm.handlerUpload,
              "before-upload": _vm.beforePictrueUpload
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "personalIdBox" }, [
        _c("img", {
          staticClass: "IdIcon",
          attrs: {
            src: require("../../../assets/images/newPersonalCenter/idIcon.png")
          }
        }),
        _vm._v("\n      " + _vm._s(_vm.personnalId) + "\n    ")
      ])
    ]),
    _c("div", { staticClass: "editBasicInformation" }, [
      _vm._m(0),
      _c("div", { staticClass: "editContent" }, [
        _c(
          "div",
          { staticClass: "editItem" },
          [
            _vm.inputNickNameFlag
              ? _c("el-input", {
                  ref: "nicknameInputter",
                  attrs: { placeholder: _vm.placeholderIcon },
                  on: { blur: _vm.nicknameBlur },
                  model: {
                    value: _vm.nickname,
                    callback: function($$v) {
                      _vm.nickname = $$v
                    },
                    expression: "nickname"
                  }
                })
              : _c(
                  "div",
                  {
                    staticClass: "showNickNameItem",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.startInputNickname.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _c("span", { staticClass: "nicknameText" }, [
                      _vm._v(_vm._s(_vm.nickname))
                    ]),
                    _c("img", {
                      staticClass: "inputIcon",
                      attrs: {
                        src: require("../../../assets/images/newEditCenter/editIcon.png"),
                        alt: ""
                      }
                    })
                  ]
                )
          ],
          1
        ),
        _c("div", { staticClass: "editItem" }, [
          _c(
            "div",
            { staticClass: "editGenderItem" },
            _vm._l(_vm.genderDataBag, function(item, index) {
              return _c(
                "div",
                {
                  key: item.gender,
                  staticClass: "genderOptions",
                  class: item.isSelected ? "genderIsSelected" : "",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.selectThisGender(index)
                    }
                  }
                },
                [
                  _c("span", {
                    staticClass: "iconfont_Me iconStyle",
                    class: item.className
                  })
                ]
              )
            }),
            0
          )
        ]),
        _c(
          "div",
          { staticClass: "editItem", style: { paddingBottom: "12px" } },
          _vm._l(_vm.tags, function(item, index) {
            return _c("Label", {
              key: index + item,
              style: { minWidth: "90px", marginBottom: "8px" },
              attrs: {
                labelContent: item,
                labelIndex: index,
                CanBeDeleted: item != "" ? true : false,
                CanBeAdded: item == "" ? true : false
              },
              on: {
                addNewLabelTag: _vm.addNewLabelTag,
                deleteLabelTag: _vm.deleteLabelTag
              }
            })
          }),
          1
        )
      ])
    ]),
    _c("div", { staticClass: "editBasicInformation" }, [
      _vm._m(1),
      _c("div", { staticClass: "editContent" }, [
        _c("div", { staticClass: "editItem" }, [
          _c(
            "div",
            { staticClass: "showAreaItem" },
            [
              _c(
                "el-popover",
                {
                  attrs: {
                    placement: "right-end",
                    width: "320",
                    "popper-class": "pageHeaderPopper"
                  },
                  model: {
                    value: _vm.popoverVisible,
                    callback: function($$v) {
                      _vm.popoverVisible = $$v
                    },
                    expression: "popoverVisible"
                  }
                },
                [
                  _c("AddressSelector", {
                    attrs: {
                      popoverVisible: _vm.popoverVisible,
                      fillInByDefault: _vm.countryCode,
                      isSingleChoice: true
                    },
                    on: { closePopover: _vm.closePopover }
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "showAreaBox",
                      attrs: { slot: "reference" },
                      slot: "reference"
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.countryName !== "" ? _vm.countryName : ""
                          ) +
                          "\n              "
                      ),
                      _c("span", {
                        staticClass: "iconfont_Me icon-down iconStyle"
                      })
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ])
      ])
    ]),
    _c("div", { staticClass: "editBasicInformation" }, [
      _vm._m(2),
      _c(
        "div",
        { staticClass: "editContent" },
        [
          _c("TypeOfEditCenter", {
            attrs: { categories: _vm.categories },
            on: { getHasSelectedType: _vm.getHasSelectedType }
          })
        ],
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "editorQuillBox" },
      [_c("EditorQuill", { attrs: { personalInfo: _vm.personalInfo } })],
      1
    ),
    _c("div", { staticClass: "editFooterButton" }, [
      _c(
        "div",
        {
          staticClass: "buttonStyle closeButton",
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.goBackPreviousPage.apply(null, arguments)
            }
          }
        },
        [_c("span", { staticClass: "iconfont_Me icon-x iconStyle" })]
      ),
      _c(
        "div",
        {
          staticClass: "buttonStyle",
          class: _vm.allowSubmitInformation ? "commomButtonHover" : "",
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.startSubmitUserinfo.apply(null, arguments)
            }
          }
        },
        [_c("span", { staticClass: "iconfont_Me icon-check iconStyle" })]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "editIcon" }, [
      _c("span", { staticClass: "iconfont_Me icon-user iconStyle" })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "editIcon" }, [
      _c("span", { staticClass: "iconfont_Me icon-position iconStyle" })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "editIcon" }, [
      _c("span", { staticClass: "iconfont_Me icon-class iconStyle" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }