const toolOptions = [
  ["bold"],
  [{ list: "ordered" }, { list: "bullet" }],
  ['richTextPicture','richTextVideo','richTextFile']
]
export default {
  placeholder: '\ue6a1',
  modules: {
    toolbar: {
      container: toolOptions
    }
  },
  initCustomButton() {
    const richTextPicture = document.querySelector('.ql-richTextPicture');
    const richTextVideo = document.querySelector('.ql-richTextVideo');
    const richTextFile = document.querySelector('.ql-richTextFile');
    richTextPicture.style.cssText = "width:auto;display: flex;align-items: center;justify-content: center;margin-right:15px";
    richTextVideo.style.cssText = "width:auto;display: flex;align-items: center;justify-content: center;margin-right:15px";
    richTextFile.style.cssText = "width:auto;display: flex;align-items: center;justify-content: center";
    richTextPicture.innerHTML="<span class='iconfont_Me icon-picture1' style='font-size:24px;color:#333333;font-weight:bold'></span>";
    richTextVideo.innerHTML="<span class='iconfont_Me icon-video' style='font-size:24px;color:#333333;font-weight:bold'></span>";
    richTextFile.innerHTML="<span class='iconfont_Me icon-paperclip' style='font-size:24px;color:#333333;font-weight:bold'></span>";
  }
};