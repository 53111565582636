import { render, staticRenderFns } from "./editPersonalInformation.vue?vue&type=template&id=4523a390&scoped=true"
import script from "./editPersonalInformation.vue?vue&type=script&lang=js"
export * from "./editPersonalInformation.vue?vue&type=script&lang=js"
import style0 from "./editPersonalInformation.vue?vue&type=style&index=0&id=4523a390&lang=stylus&scoped=true"
import style1 from "./editPersonalInformation.vue?vue&type=style&index=1&id=4523a390&lang=stylus"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4523a390",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\main project\\new_front_melinked\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4523a390')) {
      api.createRecord('4523a390', component.options)
    } else {
      api.reload('4523a390', component.options)
    }
    module.hot.accept("./editPersonalInformation.vue?vue&type=template&id=4523a390&scoped=true", function () {
      api.rerender('4523a390', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/newVersion/pageViews/editCenter/editPersonalInformation.vue"
export default component.exports