<template>
  <div class="quillEditorForEditPage">
    <div class="loading" v-if="progress < 100 && progress > 0 && isShowLoading">
      <el-progress
        :width="46"
        :stroke-width="3"
        color="#ffffff"
        type="circle"
        :percentage="progress"
      ></el-progress>
      <!-- 中途取消上传按钮 -->
      <div class="deleteThisPicture" @click.stop="cancleUpload()">
        <span class="iconfont_Me icon-x deleteIconStyle"></span>
      </div>
    </div>
    <quillEditor
      v-loading="editorLoading"
      class="ql-editorInner"
      v-model="editorModel"
      ref="myTextEditor"
      :options="editorOption"
      @ready="onEditorReady($event)"
      @change="onEditorChange($event)"
    >
    </quillEditor>
    <el-upload
      v-show="false"
      ref="uploadPictureBox"
      accept="image/*"
      action="/"
      :multiple="false"
      :show-file-list="false"
      :http-request="handlerUpload"
      :before-upload="beforePictrueUpload"
    >
    </el-upload>
    <el-upload
      v-show="false"
      ref="uploadVideoBox"
      action="/"
      accept="video/*"
      :show-file-list="false"
      :http-request="handlerUpload"
      :before-upload="beforeVideoUpload"
    >
      <i class="el-icon-video-camera"></i>
    </el-upload>
    <el-upload
      v-show="false"
      ref="uploadFileBox"
      action="/"
      :accept="$store.state.publishArticle.attachmentType"
      :show-file-list="false"
      :http-request="handlerUpload"
      :before-upload="beforeFileUpload"
    >
      <i class="el-icon-video-camera"></i>
    </el-upload>
  </div>
</template>
<script>
import { getPageCrawl } from "@/api/newVersion/publishArticle/index.js";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import * as Quill from "quill";
import quillConfig from "./quill-config.js";
import { handlerUploadFileToQiniu } from "@/api/qiniu";
import ImageResize from "quill-image-resize-module";
import Video from "./registEditorVideo";
import File from "./registEditorFile";
import QuillText from "./quillText";
import REG_URI from "./verifyConfiguration";
Quill.register("modules/imageResize", ImageResize);
// 这两个是超链接预览组件，后期需要的时候打开==========
// import HyperlinkText from './hyperlinkText';
// import HyperlinkPreview from './hyperlinkPreview';
// Quill.register(HyperlinkPreview);
// Quill.register(HyperlinkText);
// =================================
Quill.register(Video, true);
Quill.register(File, true);
Quill.register(QuillText);
export default {
  data() {
    return {
      editorLoading: false,
      editorOption: {
        placeholder: quillConfig.placeholder,
        modules: {
          // 监听剪切粘贴操作超链接，这里不需要处理，后期视情况开启
          // clipboard: {
          //   matchers: [
          //     [Node.TEXT_NODE, this.handleCustomMatcher]
          //   ]
          // },
          // 监听编辑器按键，处理超链接，这里不需要处理，后期视情况开启
          // keyboard:{
          //   bindings: {
          //     tab1: {
          //       key: 13,
          //       handler: this.setEnterBar
          //     },
          //     tab2: {
          //       key: 32,
          //       handler: this.setSpaceBar
          //     },
          //   }
          // },
          toolbar: {
            container: quillConfig.modules.toolbar.container,
            handlers: {
              shadeBox: null,
              richTextPicture: () => {
                this.richTextPicture();
              },
              richTextVideo: () => {
                this.richTextVideo();
              },
              richTextFile: () => {
                this.richTextFile();
              },
            },
          },
          imageResize: {
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white",
            },
            modules: ["Resize"],
          },
        },
      },
      progress: 0, //上传进度
      isShowLoading: true,
      // 编辑器对象
      editor: null,
      // 编辑器默认内容
      editorModel: ``,
      // 上传后的默认回调对象
      uploadedItem: null,
      // 插入对象的类型
      uploadType: "",
      // 点击回车按钮记录
      clickEnterFlag: false,
      // 粘贴富文本内容记录（用于粘贴后处理超链接预览问题）
      isItPasted: false,
    };
  },
  components: {
    quillEditor,
  },
  beforeDestroy() {
    this.editor = null;
  },
  watch: {
    // 监听上传进度
    "uploadedItem.progress": {
      handler(val) {
        if (val) {
          this.progress = Math.round(Number(val));
        }
      },
      deep: true,
    },
    uploadedItem: {
      handler(val) {
        if (!val.isUploading) {
          this.$refs.uploadPictureBox.clearFiles();
          this.$refs.uploadVideoBox.clearFiles();
          this.$refs.uploadFileBox.clearFiles();
          this.editorSuccess(val);
        }
      },
      deep: true,
    },
    // 判断是否触发粘贴
    isItPasted: {
      handler(val, old) {
        if (val && val != old) {
          this.$nextTick(() => {
            this.isItPasted = false;
            // 开始扫描是否有超链接预览未填充
            this.hyperlinkPreviewScan();
          });
        }
      },
      deep: true,
    },
    // 判断是否有个人信息ID
    "personalInfo.providerId": {
      handler(val, old) {
        if (val && val != old) {
          this.$nextTick(() => {
            this.handleEditRichTextData();
          });
        }
      },
      deep: true,
    },
  },
  props: {
    // 个人信息详情
    personalInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    //取消上传
    cancleUpload() {
      this.subscription_2.unsubscribe();
      this.isShowLoading = false;
      this.progress = 0;
    },
    // 处理编辑的富文本数据
    handleEditRichTextData() {
      let hasContentFlag = false;
      if (this.personalInfo.richIntro !== "") {
        hasContentFlag = true;
        this.editorModel = this.personalInfo.richIntro;
        this.$forceUpdate();
      } else if (this.personalInfo.intro !== "") {
        try {
          hasContentFlag = true;
          let dataFromApp = JSON.parse(
            this.personalInfo.intro.replace(/\n/g, "")
          );
          let contents = this.reductiveStructure(dataFromApp);
          // 为了保持顺序，只能这样
          for (let i = 0; i < contents.length; i++) {
            let insertIndex = i === 0 ? i : this.editor.getLength() + 1;
            // 如果是普通文本数据
            if (
              typeof contents[i].insert === "string" &&
              contents[i].insert !== "" &&
              !contents[i].attributes
            ) {
              this.editor.insertText(insertIndex, contents[i].insert);
            }
            // 如果是加粗的标题文本数据
            if (
              typeof contents[i].insert === "string" &&
              contents[i].insert !== "" &&
              contents[i].attributes &&
              contents[i].attributes.bold
            ) {
              this.editor.insertText(
                insertIndex - 1 < 0 ? 0 : insertIndex - 1,
                contents[i].insert,
                "bold",
                true
              );
            }
            // 如果这是附件
            if (contents[i].insert.file && contents[i].insert.file !== "") {
              this.editor.insertEmbed(
                insertIndex,
                "file",
                contents[i].insert.file
              );
            }
            // 如果这是富文本图片
            if (contents[i].insert.image && contents[i].insert.image !== "") {
              this.editor.insertEmbed(
                insertIndex,
                "image",
                contents[i].insert.image
              );
            }
            // 如果这是富文本视频
            if (contents[i].insert.video && contents[i].insert.video !== "") {
              this.editor.insertEmbed(
                insertIndex,
                "video",
                contents[i].insert.video.indexOf("?vframe/jpg/offset/0") !== -1
                  ? contents[i].insert.video.split("?")[0]
                  : contents[i].insert.video
              );
            }
          }
        } catch (error) {
          console.log(error, this.personalInfo.intro);
        }
      }
      this.$nextTick(() => {
        if (this.editor !== null && hasContentFlag) {
          this.editor.insertText(this.editor.getLength() + 1, "\n");
          this.editor.setSelection(this.editor.getLength() + 1);
        }
      });
    },
    // 将数据结构处理成编辑器可以渲染的结构
    reductiveStructure(dataFromApp) {
      let processedArray = dataFromApp.map((item) => {
        let handleItem = {};
        if (item.key === "text") {
          handleItem.insert = item.content;
        } else if (item.key === "title") {
          handleItem.attributes = { bold: true };
          handleItem.insert = item.content;
        } else if (item.key === "file") {
          handleItem.insert = {
            file: JSON.stringify({ name: item.fileName, path: item.content }),
          };
        } else if (item.key === "image") {
          handleItem.insert = { image: item.content };
        } else if (item.key === "video") {
          handleItem.insert = { video: item.content };
        }
        return handleItem;
      });
      return processedArray;
    },
    // 编辑器准备完毕
    onEditorReady() {
      quillConfig.initCustomButton();
      this.editor = this.$refs.myTextEditor.quill;
      this.$store.commit("setEditUserInfoEditorQuill", this.editor);
    },
    // 编辑器触发
    onEditorChange({ html, text }) {
      this.$store.commit("setEditedUserInfoHTMLRecord", html);
    },
    // 获取当前最新的焦点位置
    getNewSelection() {
      return new Promise((resolve, reject) => {
        let range = this.editor.getSelection();
        let rangeIndex = 0;
        if (range == null) {
          rangeIndex = 0;
        } else {
          rangeIndex = range.index;
        }
        resolve(rangeIndex);
      });
    },
    // 编辑器上传图片
    richTextPicture() {
      this.uploadType = "image";
      this.$refs.uploadPictureBox.$children[0].$refs.input.click();
    },
    // 编辑器上传视频
    richTextVideo() {
      this.uploadType = "video";
      this.$refs.uploadVideoBox.$children[0].$refs.input.click();
    },
    // 编辑器上传附件
    richTextFile() {
      this.uploadType = "file";
      this.$refs.uploadFileBox.$children[0].$refs.input.click();
    },
    // 在上传图片以前执行检查
    beforePictrueUpload(file, fileList) {
      if (
        this.matchFileSuffixType(file.name) === "image" &&
        file.size > 0 &&
        (file.size / (1024 * 1024)).toFixed(2) < 200
      ) {
        return true;
      }
      this.$message({
        type: "error",
        message: "<200M",
      });
      return false;
    },
    // 在上传视频以前执行检查
    beforeVideoUpload(file, fileList) {
      if (
        this.matchFileSuffixType(file.name) === "video" &&
        file.size > 0 &&
        (file.size / (1024 * 1024)).toFixed(2) < 200
      ) {
        return true;
      }
      this.$message({
        type: "error",
        message: "<200M",
      });
      return false;
    },
    // 在上传文件以前执行检查
    beforeFileUpload(file, fileList) {
      if (
        this.matchFileSuffixType(file.name) !== "image" &&
        this.matchFileSuffixType(file.name) !== "video" &&
        file.size > 0 &&
        (file.size / (1024 * 1024)).toFixed(2) < 200
      ) {
        return true;
      }
      this.$message({
        type: "error",
        message: "<200M",
      });
      return false;
    },
    // 开始调用隐藏的上传按钮
    handlerUpload(files) {
      if (0 < this.progress && this.progress < 100) {
        this.$message({
          type: "info",
          message: this.$t("completeTips"),
        });
        return false;
      }
      this.isShowLoading = true;
      handlerUploadFileToQiniu(files).then((item) => {
        this.uploadedItem = item;
      });
    },
    // 如果编辑器上传图片或视频成功
    editorSuccess(res) {
      if (res) {
        let range = this.editor.getSelection();
        let index = 0;
        if (range == null) {
          index = 0;
        } else {
          index = range.index;
        }
        if (this.uploadType !== "file") {
          this.editor.insertEmbed(index, this.uploadType, res.path);
        } else {
          this.editor.insertEmbed(
            index,
            this.uploadType,
            JSON.stringify({ name: res.name, path: res.path })
          );
        }
        this.editor.setSelection(index + 1);
      } else {
        console.log("上传附件插入失败");
      }
    },
    // ==========================编辑个人信息富文本暂不需要处理超链接 start===============================
    // 超链接预览删除自己
    closeHyperlinkBlock(e) {
      e.parentNode.parentNode.remove();
      this.$nextTick(() => {
        this.editorModel = this.$refs.myTextEditor.$refs.editor.children[0].innerHTML;
        this.editor.setSelection(this.editor.getLength() + 1);
      });
    },
    // 粘贴超链接处理拦截
    handleCustomMatcher(node, Delta) {
      let ops = [];
      let links = [];
      let splitText = "$+*-melinked-*+$";
      Delta.ops.forEach((op) => {
        if (op.insert && typeof op.insert === "string") {
          op.insert = op.insert.replace(REG_URI, (match) => {
            if (match.indexOf("'") === -1) {
              links.push([
                {
                  insert: {
                    hyperlinkText: JSON.stringify({
                      showLink: match,
                      jumpLink: match,
                    }),
                  },
                },
                {
                  insert: { hyperlinkPreview: JSON.stringify({ link: match }) },
                },
              ]);
              return splitText;
            } else {
              return match;
            }
          });
          if (links.length !== 0) {
            op.insert.split(splitText).forEach((e, i) => {
              if (i == 0) {
                ops.push({ insert: e });
                // 这里添加一个空字符串是为了防止超链接预览错乱
                ops.push({ insert: { quillText: "" } });
              } else {
                ops.push({ insert: { quillText: e } });
              }
              if (links[i]) {
                ops.push(...links[i]);
              }
            });
          } else {
            ops.push(op);
          }
        }
      });
      Delta.ops = ops;
      this.isItPasted = true;
      return Delta;
    },
    // 超链接预览扫描为粘贴复制功能准备
    async hyperlinkPreviewScan() {
      this.editorLoading = true;
      // 获取富文本中的节点数组
      let contents = this.editor.getContents(0, this.editor.getLength() + 1)
        .ops;
      let pageCrawlArr = [];
      // 判断是否改变了数组
      let isChange = false;
      // 第一遍历，为了找到还没有填充预览信息的预览框，获取到这些预览框对应的预览信息
      for (let i = 0; i < contents.length; i++) {
        if (contents[i].insert.hyperlinkPreview) {
          let pageCrawl = JSON.parse(contents[i].insert.hyperlinkPreview);
          // 判断这个超链接预览节点是否获取过网页抓取内容
          if (
            !pageCrawl.image &&
            !pageCrawl.title &&
            !pageCrawl.content &&
            pageCrawl.link
          ) {
            // 开始获取超链接对应的网页内容
            await this.getPageCrawlContent([pageCrawl.link]).then(
              (pageCrawlContent) => {
                isChange = true;
                pageCrawlArr.push(pageCrawlContent);
                if (
                  pageCrawlContent[pageCrawl.link].content !== "" ||
                  pageCrawlContent[pageCrawl.link].image !== "" ||
                  pageCrawlContent[pageCrawl.link].title !== ""
                ) {
                  // 获取网页抓取内容成功后将当前超链接也赋值到内容里面
                  pageCrawlContent[pageCrawl.link].link = pageCrawl.link;
                  // 将获取过后的内容填充到富文本节点中去
                  contents[i].insert.hyperlinkPreview = JSON.stringify(
                    pageCrawlContent[pageCrawl.link]
                  );
                } else {
                  // 如果没有这个超链接预览则默认将预览设置为空，等待删除
                  contents[i].insert.hyperlinkPreview = null;
                }
              }
            );
          }
        }
      }
      // 第二遍历，为了将已显示的超链接预览对应的超链接文本的跳转路径替换为真实路径
      for (let i = 0; i < contents.length; i++) {
        if (contents[i].insert.hyperlinkText) {
          let pageCrawl = JSON.parse(contents[i].insert.hyperlinkText);
          pageCrawlArr.forEach((e) => {
            // 如果有这个值的话，则默认这是刚刚填充的预览对应的超链接文本，将真实url填充到hyperlinkText中
            if (e[pageCrawl.showLink]) {
              isChange = true;
              pageCrawl.jumpLink = e[pageCrawl.showLink].url;
              contents[i].insert.hyperlinkText = JSON.stringify(pageCrawl);
            }
          });
        }
      }
      if (isChange) {
        contents = contents.filter((item) => {
          return item.insert.hyperlinkPreview !== null;
        });
        // 将处理完了的超链接预览重新填入富文本中
        this.editor.setContents(contents);
      }
      this.editorLoading = false;
    },
    // 监听回车按钮，触发超链接预览
    setEnterBar() {
      this.getNewSelection().then((rangeIndex) => {
        // 这里像文本中插入了一个空格，所以后面rangeIndex都得加1
        this.editor.insertText(rangeIndex, "\n");
        this.clickEnterFlag = true;
        rangeIndex++;
        this.hyperlinkReady(rangeIndex);
      });
    },
    // 监听空格按钮，触发超链接预览
    setSpaceBar() {
      this.getNewSelection().then((rangeIndex) => {
        // 这里像文本中插入了一个空格，所以后面rangeIndex都得加1
        this.editor.insertText(rangeIndex, " ");
        rangeIndex++;
        this.hyperlinkReady(rangeIndex);
      });
    },
    // 超链接预览前准备工作，rangeIndex为当前光标所在的位置
    hyperlinkReady(rangeIndex) {
      this.editorLoading = true;
      this.checkHyperlink(rangeIndex).then((link) => {
        if (link !== "") {
          // 获取网页抓取内容
          this.getPageCrawlContent([link])
            .then((pageCrawl) => {
              this.triggerHyperlink(link, pageCrawl);
            })
            .catch((err) => {
              this.$message({
                type: "error",
                message: err,
              });
              this.clickEnterFlag = false;
            })
            .finally(() => {
              this.editorLoading = false;
            });
        } else {
          this.clickEnterFlag = false;
          this.editorLoading = false;
        }
      });
    },
    // 针对按键的超链接校验(rangePlusIndex:当前光标的所在的位置下标)
    checkHyperlink(rangePlusIndex) {
      return new Promise((resolve, reject) => {
        // 用于储存匹配到的超链接字符串
        let processedData = "";
        // 获取富文本中的节点所有数据
        let contents = this.editor.getContents().ops;
        // =========下面这步主要是为了获取根据光标位置获取到超链接所在的数组位置下标==========
        // 获取超链接所在的数组下标
        let contentsIndex =
          this.editor.getContents(0, rangePlusIndex).ops.length - 1;
        // =========上面这步主要是为了获取根据光标位置获取到超链接所在的数组位置下标==========
        // 过滤检测一波文本
        contents[contentsIndex].insert = contents[contentsIndex].insert.replace(
          REG_URI,
          (match) => {
            // 匹配到超链接后，并且必须超链接和空格键之间不能有其他字符串才会开始预览
            if (match.indexOf("'") === -1) {
              processedData = match;
              return "";
            } else {
              return match;
            }
          }
        );
        if (processedData != "") {
          // 这里应该把整个已处理过的富文本对象传入
          this.editor.setContents(contents);
          // 由于超链接已经被过滤，所以光标需要向移动到超链接过滤的位置上去
          this.editor.setSelection(rangePlusIndex - processedData.length);
        }
        resolve(processedData);
      });
    },
    // 触发超链接预览
    triggerHyperlink(link, pageCrawl) {
      this.getNewSelection().then((rangeIndex) => {
        this.editor.insertEmbed(
          rangeIndex,
          "hyperlinkText",
          JSON.stringify({ showLink: link, jumpLink: pageCrawl[link].url })
        );
        rangeIndex++;
        this.editor.setSelection(rangeIndex);
        this.$nextTick(() => {
          if (this.clickEnterFlag) {
            this.clickEnterFlag = false;
          } else {
            rangeIndex++;
          }
          if (
            pageCrawl[link].content !== "" ||
            pageCrawl[link].image !== "" ||
            pageCrawl[link].title !== ""
          ) {
            // 将超链接地址赋值到对应的Object中
            pageCrawl[link].link = link;
            let pageCrawlString = JSON.stringify(pageCrawl[link]);
            this.editor.insertEmbed(
              rangeIndex,
              "hyperlinkPreview",
              pageCrawlString
            );
            rangeIndex++;
          }
          this.editor.setSelection(rangeIndex);
        });
      });
    },
    // 获取网页抓取内容
    getPageCrawlContent(urls) {
      return new Promise((resolve, reject) => {
        getPageCrawl(urls).then((result) => {
          if (result.code == 200) {
            resolve(result.data.data);
          } else {
            reject(result.message);
          }
        });
      });
    },
    // ==========================编辑个人信息富文本暂不需要处理超链接 end===============================
  },
};
</script>
<style lang="stylus">
.quillEditorForEditPage {
  width: 100%;
  overflow: hidden;
  position: relative;

  .loading {
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 4px;
    top: 40%;
    left: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('../../publishArticle/showUpload/grounGlass.png') no-repeat;
    background-size: 100% 100%;
    z-index: 10000;

    .deleteThisPicture {
      top: -6px;
      right: -6px;
      width: 18px;
      height: 18px;
      cursor: pointer;
      border-radius: 50%;
      position: absolute;
      background: red;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .deleteIconStyle {
      color: #FFFFFF;
      font-size: 12px;
    }
  }

  .ql-editorInner {
    padding: 12px 15px 0 15px !important;

    .ql-toolbar {
      height: 60px;
      padding: 0 20px;
      display: flex;
      align-items: center;
      border: 1px solid rgba(0, 0, 0, 0.1);

      .ql-formats {
        button {
          padding: 0;
        }

        .ql-list {
          margin-right: 15px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .ql-container {
      overflow: hidden;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-top: none;

      .ql-editor {
        font-size: 14px;
        height: 350px;
        overflow-y: scroll;
        overflow-x: hidden;
        transition: max-height 0.3s;
        font-family: 'iconfont_Me';
        font-size: 14px !important;

        &::-webkit-scrollbar {
          width: 4px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 2px;
          box-shadow: inset 0 0 5px rgba(100, 100, 100, 0);
          background: #ADADAD;
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px rgba(0, 0, 0, 0);
          border-radius: 0;
          background: rgba(0, 0, 0, 0);
        }
      }
    }
  }
}
</style>
