<template>
  <div class="editPage">
    <div class="pageContentBox">
      <div class="editTitleBox">
        <div class="editTitleIconBox">
          <img class="editTitleIcon" :src="editTitleIcon">
        </div>
        <div class="editTitleText">
          {{editTitleText}}
        </div>
      </div>
      <div class="editContent">
        <EditPersonalInformation v-if="showDifferentModules==='personal'" :personalInfo="personalInfo"></EditPersonalInformation>
        <EditGroupInformation v-if="showDifferentModules==='group'" :groupInfo="groupInfo"></EditGroupInformation>
      </div>
    </div>
  </div>
</template>
<script>
import EditPersonalInformation from "./editPersonalInformation";
import EditGroupInformation from "./editGroupInformation";
import { getPersonnelDetails } from '@/api/newVersion/personalCenter';
import { getGroupDetail } from '@/api/newVersion/groupCenter';
export default {
  name: "editCenter",
  components: {
    EditPersonalInformation,
    EditGroupInformation
  },
  watch: {
    '$route.params': {
      handler(val) {
        // 判断用户是否登录过，没有登录禁止进入编辑中心，直接跳转首页
        if(!this.$store.getters.userInfo.id) {
          this.routeJump({ name: 'home'})
          return;
        }
        if(val && this.$route.params.type) {
          // 为了安全，先清空一次
          this.groupInfo = null;
          this.personalInfo = null;
          switch (this.$route.params.type) {
            case 'personal':
              this.getPersonalInfoById();
              this.showDifferentModules = 'personal';
              this.editTitleText = this.$t("personalInformation");
              this.editTitleIcon = require('../../../assets/images/newEditCenter/personalInfoIcon.png');
              break;
            case 'editGroup':
              this.getGroupInfoById();
              this.editTitleText = this.$t("editGroup");
              this.showDifferentModules = 'group';
              this.editTitleIcon = require('../../../assets/images/newEditCenter/groupIcon.png');
              break;
            case 'group':
              this.editTitleText = this.$t("createAGroup");
              this.showDifferentModules = 'group';
              this.editTitleIcon = require('../../../assets/images/newEditCenter/groupIcon.png');
              break;
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      // 个人信息
      personalInfo: null,
      // 编辑资料的标题
      editTitleText: '',
      // 编辑资料的标题图标
      editTitleIcon: null,
      // 展示不同的区域
      showDifferentModules: '',
      // 群ID，只有编辑群的时候才会需要
      groupInfo: null
    }
  },
  methods: {
    // 根据ID获取用户的详细信息
    async getPersonalInfoById() {
      // 用户只能编辑自己的信息，所以只取本地的ID
      let result = await getPersonnelDetails(this.$store.getters.userInfo.id);
      if(result.code == 200) {
        this.personalInfo = result.data.data;
      } else {
        this.$message({
          type: "error",
          message: result.message
        })
      }
    },
    // 根据ID获取群组的详细信息
    async getGroupInfoById() {
      if(this.$route.params.id) {
        let groupId = this.$Base64.decode(this.$route.params.id.replace("pai_", ""));
        let params = {
          groupId: groupId,
          userId: this.$store.getters.userInfo.id || ""
        }
        let result = await getGroupDetail(params);
        if(result.code == 1) {
          this.groupInfo = result.data[0];
        } else {
          this.$message({
            type: "error",
            message: result.message
          })
        }
      }
    }
  }
}
</script>
<style lang="stylus" scoped>
  .editPage
    width 100%;
    height calc(100vh - 60px);
    box-sizing border-box;
    background #F7F7F7;
    overflow-x hidden;
    padding-bottom 20px;
    scrollbar-width: none;
    &::-webkit-scrollbar
      width 0px;
    .pageContentBox
      width 1160px;
      min-height 0;
      overflow hidden;
      margin auto;
      display flex;
      align-content flex-start;
      justify-content space-between;
      .editTitleBox
        width 282px;
        height 56px;
        flex-shrink 0;
        background #FFFFFF;
        box-sizing border-box;
        padding 20px 0 20px 36px;
        display flex;
        align-items center;
        justify-content flex-start;
        .editTitleIconBox
          height 100%;
          width 16px;
          margin-right 12px;
          .editTitleIcon
            width 16px;
            height auto;
        .editTitleText
          height 100%;
          font-size 14px;
          color #373737;
          user-select none;
      .editContent
        flex 1;
        min-width 0;
        overflow hidden;
        margin-left 12px;
        min-height 0;
        padding 50px 80px 25px 80px;
        box-sizing border-box;
        background #FFFFFF;

</style>