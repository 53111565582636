import http from "@/utils/http";

// 编辑个人信息
export function editUserBaseinfo(userId, params) {
  return http({
    baseURL: "/",
    method: "put",
    url: `rest/personalCenter/v3/baseInfo/${userId}`,
    withCredentials: false,
    data: params
  });
}