<template>
  <div class="editPersonalInformationBox">
    <div class="personalHeaderAndId">
      <div class="personalHeaderBox">
        <el-image
          :src="formartImageUrl(personnalAvatar)"
          class="avatarForPersonal"
          :fit="'cover'"
        >
          <div slot="error" class="imageLoadError">
            <span class="iconfont_Me iconStyle icon-group-fill"></span>
          </div>
        </el-image>
        <div class="maskBox" @click.stop="uploadPersonalAvatar">
          <span class="iconfont_Me icon-shot1 iconStyle"></span>
        </div>
        <el-upload
          v-show="false"
          ref="uploadPicture"
          action="/"
          accept="image/*"
          :multiple="true"
          :http-request="handlerUpload"
          :before-upload="beforePictrueUpload"
        ></el-upload>
      </div>
      <div class="personalIdBox">
        <img
          class="IdIcon"
          :src="require('../../../assets/images/newPersonalCenter/idIcon.png')"
        />
        {{ personnalId }}
      </div>
    </div>
    <div class="editBasicInformation">
      <div class="editIcon">
        <span class="iconfont_Me icon-user iconStyle"></span>
      </div>
      <div class="editContent">
        <div class="editItem">
          <el-input
            ref="nicknameInputter"
            @blur="nicknameBlur"
            v-if="inputNickNameFlag"
            v-model="nickname"
            :placeholder="placeholderIcon"
          ></el-input>
          <div v-else class="showNickNameItem" @click.stop="startInputNickname">
            <span class="nicknameText">{{ nickname }}</span>
            <img
              class="inputIcon"
              :src="
                require('../../../assets/images/newEditCenter/editIcon.png')
              "
              alt=""
            />
          </div>
        </div>
        <div class="editItem">
          <div class="editGenderItem">
            <div
              class="genderOptions"
              v-for="(item, index) in genderDataBag"
              :key="item.gender"
              :class="item.isSelected ? 'genderIsSelected' : ''"
              @click.stop="selectThisGender(index)"
            >
              <span
                class="iconfont_Me iconStyle"
                :class="item.className"
              ></span>
            </div>
          </div>
        </div>
        <div class="editItem" :style="{ paddingBottom: '12px' }">
          <Label
            :style="{ minWidth: '90px', marginBottom: '8px' }"
            @addNewLabelTag="addNewLabelTag"
            @deleteLabelTag="deleteLabelTag"
            v-for="(item, index) in tags"
            :labelContent="item"
            :labelIndex="index"
            :key="index + item"
            :CanBeDeleted="item != '' ? true : false"
            :CanBeAdded="item == '' ? true : false"
          ></Label>
        </div>
      </div>
    </div>
    <div class="editBasicInformation">
      <div class="editIcon">
        <span class="iconfont_Me icon-position iconStyle"></span>
      </div>
      <div class="editContent">
        <div class="editItem">
          <div class="showAreaItem">
            <el-popover
              placement="right-end"
              width="320"
              v-model="popoverVisible"
              popper-class="pageHeaderPopper"
            >
              <AddressSelector
                :popoverVisible="popoverVisible"
                :fillInByDefault="countryCode"
                :isSingleChoice="true"
                @closePopover="closePopover"
              ></AddressSelector>
              <div class="showAreaBox" slot="reference">
                {{ countryName !== "" ? countryName : "" }}
                <span class="iconfont_Me icon-down iconStyle"></span>
              </div>
            </el-popover>
          </div>
        </div>
      </div>
    </div>
    <div class="editBasicInformation">
      <div class="editIcon">
        <span class="iconfont_Me icon-class iconStyle"></span>
      </div>
      <div class="editContent">
        <TypeOfEditCenter
          :categories="categories"
          @getHasSelectedType="getHasSelectedType"
        ></TypeOfEditCenter>
      </div>
    </div>
    <div class="editorQuillBox">
      <EditorQuill :personalInfo="personalInfo"></EditorQuill>
    </div>
    <div class="editFooterButton">
      <div class="buttonStyle closeButton" @click.stop="goBackPreviousPage">
        <span class="iconfont_Me icon-x iconStyle"></span>
      </div>
      <div
        class="buttonStyle"
        :class="allowSubmitInformation ? 'commomButtonHover' : ''"
        @click.stop="startSubmitUserinfo"
      >
        <span class="iconfont_Me icon-check iconStyle"></span>
      </div>
    </div>
  </div>
</template>
<script>
import Label from "../../components/label";
import AddressSelector from "../../components/addressSelector";
import TypeOfEditCenter from "./typeOfEditCenter";
import { handlerUploadFileToQiniu } from "@/api/qiniu";
import EditorQuill from "./editorQuill/index";
import { editUserBaseinfo } from "@/api/newVersion/editCenter";
import Bus from "@/utils/bus.js";
export default {
  name: "editPersonalInformation",
  props: {
    // 个人信息
    personalInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {
    Label,
    EditorQuill,
    AddressSelector,
    TypeOfEditCenter,
  },
  watch: {
    // 监听用户
    "personalInfo.providerId": {
      handler(val) {
        this.initData();
      },
      deep: true,
    },
    // 监听昵称输入框的状态
    inputNickNameFlag: {
      handler(val, old) {
        if (val && val !== old) {
          this.$nextTick(() => {
            this.$refs.nicknameInputter.focus();
          });
        }
      },
      deep: true,
    },
    // 监听上传封面的对象
    personalAvatarObj: {
      handler(val, old) {
        if (val.progress === "100.00") {
          this.personnalAvatar = val.path;
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      // 用户头像
      personnalAvatar: "",
      // 用户ID
      personnalId: "",
      // 昵称文本
      nickname: "",
      // 性别
      gender: "",
      // 性别的值
      genderValue: "",
      // 用户头像的上传对象，用于监听上传是否成功
      personalAvatarObj: null,
      // 是否展示昵称输入框
      inputNickNameFlag: false,
      // 昵称输入框默认文本
      placeholderIcon: "\ue6a1",
      // 性别集合
      genderDataBag: [
        {
          gender: "male",
          value: "1",
          isSelected: false,
          className: "icon-male-fill",
        },
        {
          gender: "female",
          value: "0",
          isSelected: false,
          className: "icon-female-fill",
        },
      ],
      // 标签集合
      tags: [""],
      // 地区选择气泡弹窗状态
      popoverVisible: false,
      // 选择的国家或地区的编码
      countryCode: "",
      // 选择类型的集合
      categories: "",
      // 选择类型的数组集合
      categorieArr: [],
    };
  },
  computed: {
    // 根据国家编码输入国家名字
    countryName() {
      let countryName = "";
      if (
        this.countryCode !== "" &&
        this.$store.state.homePage.countriesCollection.length != 0
      ) {
        // 将状态管理器中的国家地区数据进行深拷贝
        let handleCountries = _.cloneDeep(
          this.$store.state.homePage.countriesCollection
        );
        handleCountries.forEach((element) => {
          element.countries.forEach((item) => {
            if (item.countryCode === this.countryCode) {
              countryName = item.countryName;
            }
          });
        });
      }
      return countryName;
    },
    // 可以上传信息
    allowSubmitInformation() {
      return (
        this.personnalAvatar !== "" &&
        this.personnalId !== "" &&
        this.nickname !== "" &&
        this.gender !== ""
      );
    },
  },
  methods: {
    // 初始化数据
    initData() {
      this.nickname = this.personalInfo.name;
      this.personnalAvatar = this.personalInfo.profilePhoto;
      this.personnalId = this.personalInfo.ucId;
      this.genderDataBag = this.genderDataBag.map((item) => {
        item.isSelected = false;
        if (item.gender === this.personalInfo.gender) {
          item.isSelected = true;
          this.genderValue = item.value;
        }
        return item;
      });
      this.gender = this.personalInfo.gender;
      this.genderDataBag = this.genderDataBag.map((item) => {
        item.isSelected = false;
        if (this.gender === item.gender) {
          item.isSelected = true;
        }
        return item;
      });
      if (this.personalInfo.skills && this.personalInfo.skills !== "") {
        this.tags = this.personalInfo.skills.split(",");
        this.tags.push("");
      }
      this.countryCode =
        this.personalInfo.country && this.personalInfo.country !== "null"
          ? this.personalInfo.country
          : "";
      this.categories = this.personalInfo.categories;
      this.$forceUpdate();
    },
    // 上传用户头像
    uploadPersonalAvatar() {
      this.$refs.uploadPicture.$children[0].$refs.input.click();
    },
    // 在上传图片以前执行检查
    beforePictrueUpload(file, fileList) {
      if (
        this.matchFileSuffixType(file.name) === "image" &&
        file.size > 0 &&
        (file.size / (1024 * 1024)).toFixed(2) < 200
      ) {
        return true;
      }
      return false;
    },
    // 开始上传图片
    handlerUpload(files) {
      handlerUploadFileToQiniu(files).then(async (item) => {
        this.$refs.uploadPicture.clearFiles();
        this.personalAvatarObj = item;
      });
    },
    // 昵称输入框失去焦点
    nicknameBlur() {
      this.inputNickNameFlag = false;
    },
    // 开始输入昵称
    startInputNickname() {
      this.inputNickNameFlag = true;
    },
    // 选择这个性别
    selectThisGender(index) {
      this.genderDataBag = this.genderDataBag.map((item) => {
        item.isSelected = false;
        return item;
      });
      this.genderDataBag[index].isSelected = true;
      this.gender = this.genderDataBag[index].gender;
      this.genderValue = this.genderDataBag[index].value;
      this.$forceUpdate();
    },
    // 添加新的标签
    addNewLabelTag(text, index) {
      if (this.tags[index] == "") {
        this.tags[index] = text;
        this.tags.push("");
      }
    },
    // 删除标签
    deleteLabelTag(index) {
      this.tags.splice(index, 1);
    },
    // 关闭地址选择气泡弹窗
    closePopover(countries) {
      this.countryCode = countries;
      this.$nextTick(() => {
        this.popoverVisible = !this.popoverVisible;
      });
    },
    // 获取已选中的类型
    getHasSelectedType(hasSelectedType) {
      this.categorieArr = hasSelectedType;
    },
    // 回到上一个页面
    goBackPreviousPage() {
      this.$router.go(-1);
    },
    // 开始上传用户信息
    startSubmitUserinfo() {
      if (!this.allowSubmitInformation) {
        return;
      }
      if (this.$store.state.editCenter.editUserInfoEditorQuill) {
        // 获取富文本编辑器内容的Object对象
        let editorContents = this.$store.state.editCenter.editUserInfoEditorQuill.getContents();
        this.handleEditorContent(editorContents).then(
          async (handleContents) => {
            let params = {
              aboutMe: this.personalInfo.aboutMe,
              country: this.countryCode,
              gender: this.genderValue,
              icanDo: "",
              intro:
                handleContents.length !== 0
                  ? JSON.stringify(handleContents)
                  : "",
              language: localStorage.getItem("langCode") || 1,
              name: this.nickname,
              profilePic: this.personnalAvatar,
              richIntro: this.$store.state.editCenter.editedUserInfoHTMLRecord.replace(
                /&nbsp;/gi,
                " "
              ),
              userId: this.$store.getters.userInfo.id,
              category: this.handleSubmitCategoryId() || this.categories,
              tags: this.handleSubmitTags(),
            };
            let result = await editUserBaseinfo(
              this.$store.getters.userInfo.id,
              params
            );
            if (result.code === 1) {
              let userinfo = JSON.parse(
                JSON.stringify(this.$store.getters.userInfo)
              );
              userinfo.avatar = this.personnalAvatar;
              userinfo.name = this.nickname;
              this.$store.commit("setUserinfo", userinfo);
              this.$nextTick(() => {
                this.routeJump({
                  name: "personalCenter",
                  params: { id: this.$store.getters.userInfo.id },
                });
              });
            } else {
              this.$message({
                type: "error",
                message: result.message,
              });
            }
          }
        );
      }
    },
    // 处理类型数据结构
    handleSubmitCategoryId() {
      let categoryId = "";
      if (this.categorieArr.length != 0) {
        this.categorieArr.forEach((element) => {
          categoryId += element.id + ",";
        });
        categoryId = categoryId.slice(0, categoryId.length - 1);
      }
      return categoryId;
    },
    // 处理标签数据结构
    handleSubmitTags() {
      let tags = "";
      if (this.tags.length != 0) {
        this.tags.forEach((element) => {
          if (element) {
            tags += element + ",";
          }
        });
        tags = tags.slice(0, tags.length - 1);
      }
      return tags;
    },
    // 处理富文本数据
    handleEditorContent(editorContents) {
      return new Promise((resolve, reject) => {
        let handleContents = [];
        editorContents.forEach((element) => {
          let handleObj = {};
          // 如果是普通文本的话
          if (
            element.insert &&
            typeof element.insert == "string" &&
            !element.attributes &&
            element.insert != "\n"
          ) {
            handleObj.key = "text";
            handleObj.content = element.insert;
            //如果是有样式属性但不是加粗标题的文本的的话
          } else if (
            element.insert &&
            typeof element.insert == "string" &&
            element.attributes &&
            !element.attributes.bold &&
            element.insert != "\n"
          ) {
            handleObj.key = "text";
            handleObj.content = element.insert;
            // 如果是加粗的标题文本的话
          } else if (
            element.insert &&
            typeof element.insert == "string" &&
            element.attributes &&
            element.attributes.bold
          ) {
            handleObj.key = "title";
            handleObj.content = element.insert;
            // 如果是附件的话
          } else if (element.insert && element.insert.file) {
            handleObj.key = "file";
            handleObj.content = JSON.parse(element.insert.file).path;
            handleObj.fileName = JSON.parse(element.insert.file).name;
            // 如果是视频的话
          } else if (element.insert && element.insert.image) {
            handleObj.key = "image";
            handleObj.content = element.insert.image;
            // 如果是视频的话
          } else if (element.insert && element.insert.video) {
            handleObj.key = "video";
            handleObj.content = element.insert.video;
          }
          if (handleObj.key) {
            // 这里需要判断一下文本数据，是否有回车，如果有的话，根据回车将数据拆分为多个文本数据
            if (
              (handleObj.key === "text" || handleObj.key === "title") &&
              handleObj.content.indexOf("\n") !== -1
            ) {
              let splitArr = handleObj.content.split("\n").filter((item) => {
                return item !== "";
              });
              splitArr.forEach((item) => {
                handleContents.push({
                  key: "text",
                  content: item.trim().replace(/&nbsp;/gi, " "),
                });
              });
            } else {
              try {
                handleObj.content = handleObj.content
                  .trim()
                  .replace(/&nbsp;/gi, " ");
              } catch (error) {
                console.log(error);
              }
              handleContents.push(handleObj);
            }
          }
        });
        resolve(handleContents);
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.editPersonalInformationBox
  width 100%;
  overflow hidden;
  .personalHeaderAndId
    height 86px;
    width 100%;
    overflow hidden;
    display flex;
    align-items center;
    justify-content flex-start;
    .personalHeaderBox
      width 86px;
      height 86px;
      flex-shrink 0;
      overflow hidden;
      border-radius 50%;
      position relative;
      &:hover
        .maskBox
          opacity 1;
      .maskBox
        top 0;
        left 0;
        z-index 2;
        width 100%;
        height 100%;
        opacity 0;
        cursor pointer;
        position absolute;
        transition opacity .3s;
        background rgba(0,0,0,0.4);
        line-height 86px;
        text-align center;
        .iconStyle
          font-size 36px;
          color #FFFFFF;
    .personalIdBox
      flex 1;
      min-width 0;
      display flex;
      align-items center;
      justify-content flex-start;
      box-sizing border-box;
      padding-left 20px;
      font-size 14px;
      color #666666;
      .IdIcon
        width 16px;
        object-fit: cover;
        margin-right 10px;
  .editBasicInformation
    width 100%;
    min-height 0;
    overflow hidden;
    display flex;
    align-items flex-start;
    justify-content space-between;
    &:first-child
      margin-top 25px;
    .editIcon
      width 32px;
      height 32px;
      flex-shrink 0;
      border-radius 50%;
      background #F5F5F5;
      margin 19px 30px 0 0;
      display flex;
      align-items center;
      justify-content center;
      .iconStyle
        font-size 20px;
        color #333333;
    .editContent
      flex 1;
      min-width 0;
      overflow hidden;
      .editItem
        padding 20px 25px;
        box-sizing border-box;
        border-bottom 1px solid #F0F0F0;
        .showNickNameItem
          height 30px;
          cursor pointer;
          user-select none;
          font-size 14px;
          color #343434;
          display flex;
          align-items center;
          justify-content flex-start;
          .nicknameText
            margin-right 20px;
          .inputIcon
            width 12px;
            height 12px;
        .editGenderItem
          height 36px;
          width 100%;
          display flex;
          align-items center;
          justify-content flex-start;
          .genderOptions
            width 36px;
            height 36px;
            border-radius 50%;
            background #F4F5F7;
            cursor pointer;
            display flex;
            align-items center;
            justify-content center;
            margin-right 40px;
            transition background .3s;
            &:last-child
              margin-right 0;
            .iconStyle
              color #343434;
              font-size 18px;
              transition color .3s;
          .genderIsSelected
            background #33CC66;
            .iconStyle
              color #FFFFFF !important;
      .showAreaItem
        width 100%;
        height 30px;
        line-height 30px;
        .showAreaBox
          cursor pointer;
          display inline-flex;
          align-items center;
          justify-content space-between;
          font-size 14px;
          color #343434;
          .iconStyle
            font-size 12px;
            color #8F8F8F;
            margin-left 20px;
  .editorQuillBox
    width 100%;
    height auto;
    overflow hidden;
  .editFooterButton
    width 100%;
    height 34px;
    margin-top 50px;
    display flex;
    align-items center;
    justify-content center;
    .buttonStyle
      height 100%;
      width 90px;
      border-radius 4px;
      cursor pointer;
      user-select none;
      display flex;
      align-items center;
      justify-content center;
      background #CCCCCC;
      &:first-child
        margin-right 10px;
      .iconStyle
        color #FFFFFF;
        font-size 22px;
    .closeButton
      background #E6E6E6;
      transition background .3s;
      &:hover
        background #F0F0F0
</style>
<style lang="stylus">
.editContent
  .editItem
    .el-input
      .el-input__inner
        font-family 'iconfont_Me';
        caret-color #33CC66;
        height 30px;
        line-height 30px;
        border none !important;
        padding 0 !important;
.avatarForPersonal
  width 100%
  height 100%;
  .imageLoadError
    height 100%;
    width 100%;
    background #E4E5E9;
    display flex;
    align-items center;
    justify-content center;
    .iconStyle
      font-size 24px;
      color #999999
</style>
