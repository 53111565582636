var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "typeOfEditCenter", staticClass: "typeOfEditCenter" },
    [
      _c(
        "div",
        { staticClass: "whenClosed", on: { click: _vm.openTypesdialog } },
        [
          _c(
            "div",
            { staticClass: "typesBody" },
            _vm._l(_vm.needToShowWhenClose, function(item, index) {
              return _c(
                "div",
                {
                  key: item.id,
                  staticClass: "typeItem",
                  class: _vm.hasSelectedType.length != 0 ? "hasSelected" : ""
                },
                [
                  _vm._v("\n        " + _vm._s(item.name) + "\n        "),
                  _vm.hasSelectedType.length !== 0
                    ? _c("span", {
                        staticClass: "iconfont_Me icon-x iconStyle",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.deleteThisType(item)
                          }
                        }
                      })
                    : _vm._e()
                ]
              )
            }),
            0
          ),
          _vm._m(0)
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "typesDialog",
          attrs: {
            visible: _vm.typesdialog,
            width: "600",
            "before-close": _vm.closeTypesdialog,
            center: true,
            "append-to-body": true,
            "destroy-on-close": true,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.typesdialog = $event
            }
          }
        },
        [
          _c("span", {
            staticClass: "iconfont_Me icon-class",
            staticStyle: { "font-size": "36px", color: "#373737" },
            attrs: { slot: "title" },
            slot: "title"
          }),
          _c(
            "div",
            { staticClass: "whenOpen" },
            _vm._l(_vm.needToShowWhenOpen, function(item, index) {
              return _c(
                "div",
                {
                  key: item.id,
                  staticClass: "typeItem",
                  class: item.isSelected ? "hasSelected" : "",
                  on: {
                    click: function($event) {
                      return _vm.selectThis(item, index)
                    }
                  }
                },
                [_vm._v("\n        " + _vm._s(item.name) + "\n      ")]
              )
            }),
            0
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "checkButton",
                  attrs: { type: "primary" },
                  on: { click: _vm.confirm }
                },
                [
                  _c("span", {
                    staticClass: "iconfont_Me icon-check",
                    staticStyle: { "font-size": "22px", color: "#ffffff" }
                  })
                ]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "blockIcon" }, [
      _c("span", { staticClass: "iconfont_Me icon-down iconStyle" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }