const uri_suffix = [
  "\\.com",
  "\\.cn",
  "\\.net",
  "\\.org",
  "\\.gov",
  "\\.edu",
  "\\.info",
  "\\.top",
  "\\.mil",
  "\\.rts",
  "\\.firm",
  "\\.link"
];
const symbol = "[A-Za-z0-9%@&;.#=^(),:_-]";
const REG_URI = new RegExp(
  `((https://|http://|\\\\\\\\)?((${symbol}*)(\\.[A-Za-z0-9]+){0,2}(${uri_suffix.join(
    "|"
  )})|[0-9]{2,3}(\\.[0-9]{1,3}){3})(:[0-9]{0,4})?(/${symbol}*){0,}(${symbol}*)?(\\?${symbol}*=${symbol}*(&${symbol}*=${symbol}*){0,})?)`,
  "g"
);
export default REG_URI;