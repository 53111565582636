var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "editPage" }, [
    _c("div", { staticClass: "pageContentBox" }, [
      _c("div", { staticClass: "editTitleBox" }, [
        _c("div", { staticClass: "editTitleIconBox" }, [
          _c("img", {
            staticClass: "editTitleIcon",
            attrs: { src: _vm.editTitleIcon }
          })
        ]),
        _c("div", { staticClass: "editTitleText" }, [
          _vm._v("\n        " + _vm._s(_vm.editTitleText) + "\n      ")
        ])
      ]),
      _c(
        "div",
        { staticClass: "editContent" },
        [
          _vm.showDifferentModules === "personal"
            ? _c("EditPersonalInformation", {
                attrs: { personalInfo: _vm.personalInfo }
              })
            : _vm._e(),
          _vm.showDifferentModules === "group"
            ? _c("EditGroupInformation", {
                attrs: { groupInfo: _vm.groupInfo }
              })
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }